import axios from "axios";
import { URLService } from "../index";
import { adUnitUrlValues } from "../ReferenceService/ReferenceService";
import { NETWORK_TIMEOUT } from "../../utils/contants";

export type AdUnit = {
  group: string;
  name: string;
  urlKey: string;
  technicalId: string;
};
class AdUnitsService {
  adUnits: Array<AdUnit> = [];

  async initialize() {
    if (this.adUnits.length === 0) {
      const response = await axios.get(URLService.getAdUnitsURL(), { timeout: NETWORK_TIMEOUT });
      this.adUnits = response?.data?.content.map((adUnit: AdUnit) => {
        const adUnitUrlKey = Object.values(adUnitUrlValues).find((unitUrl) => adUnit.technicalId.startsWith(unitUrl.uniqueId))?.key;
        if (adUnitUrlKey) {
          return { ...adUnit, urlKey: adUnitUrlKey };
        }

        return adUnit;
      });
    }
    return null;
  }
}
export default new AdUnitsService();
