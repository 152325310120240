import { Form, Select, Typography } from "@ogury/design-system";
import { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AdUnitsService, { AdUnit } from "services/AdUnitsService/AdUnitsService";
import ReferenceService, { adUnitUrlValues } from "services/ReferenceService/ReferenceService";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { UnitData } from "../Types";
import { Row } from "../../../../components";
import styles from "../DemoPageV2.module.scss";
import { URLService } from "../../../../services";
import { CreativeDataProps } from "../../../../types";

type Props = {
  creativeData: CreativeDataProps;
  adUnits: Array<UnitData>;
};

function MobileFormTab({ creativeData, adUnits }: Props): ReactElement {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const adUnitOptions = useMemo(
    () =>
      adUnits
        .map((unit) => {
          const matchedAdUnit = AdUnitsService.adUnits.find((item: AdUnit) => item.technicalId === unit.technicalId);
          if (matchedAdUnit) {
            const { name } = matchedAdUnit;
            return { value: unit.externalId, label: name, technicalId: unit.technicalId };
          }
          return undefined;
        })
        .filter((item) => !!item)
        .sort((a, b) => (a && b ? a.label.localeCompare(b.label) : 0)),
    [adUnits]
  );

  useEffect(() => {
    form.setFieldValue("adUnit", creativeData.creativeId);
  }, [creativeData]);

  const handleAdUnitChange = (value: string, item: { technicalId: string }) => {
    if (value) {
      const { technicalId } = item;
      const adUnitUrlParam = Object.values(adUnitUrlValues).find((unitUrl) => technicalId.startsWith(unitUrl.uniqueId))?.key;
      // refresh page for proper creative reload
      navigate({
        pathname: window.location.pathname,
        search: createSearchParams({
          ...Object.fromEntries(searchParams),
          [ReferenceService.urlParameters.CID]: value,
          [ReferenceService.urlParameters.AU]: adUnitUrlParam || Object.fromEntries(searchParams)[ReferenceService.urlParameters.AU],
        }).toString(),
      });
    }
  };

  return (
    <>
      <Form form={form}>
        <Form.Item disabled name="adUnit" label={t("MobileForm.adUnitLabel")} required>
          <Select width="100%" onChange={handleAdUnitChange} options={adUnitOptions} />
        </Form.Item>
      </Form>
      <Row top={40} padding={3} className={styles.qrCode}>
        <QRCode value={`${URLService.getPublicURL()}?${searchParams.toString()}`} bgColor="#FFFFFF" fgColor="#000000" size={140} />
      </Row>
      <Row>
        <Typography.P3Regular>{t("DemoShowcase.seeMoreOnPhone", "See this demo on your phone")}</Typography.P3Regular>
      </Row>
    </>
  );
}

export default MobileFormTab;
