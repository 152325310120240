import { ReactElement } from "react";
import { CreativeDataProps } from "types/Types";
import { ReferenceService } from "services";
import { deviceType } from "services/ReferenceService/ReferenceService";
import IphoneShell from "assets/img/IphoneShell.png";
import { Creative } from "./components";
import style from "./RightPanel.module.scss";

type RightPanelProps = {
  creativeData: CreativeDataProps;
  device: string;
  forcePreview?: boolean;
};

function RightPanel({ device, creativeData, forcePreview }: RightPanelProps) {
  const creativeProperties = {
    device,
    creativeData,
    forcePreview,
  };

  function renderWrapper(children: ReactElement) {
    if (creativeData.adUnit === ReferenceService.adUnitUrlValues.BANNER.key) {
      return <div style={{ width: `${creativeData.dimension?.width}px`, height: `${creativeData.dimension?.height}px` }}>{children}</div>;
    }

    if (device === deviceType.MOBILE) {
      return (
        <div className={style.phone}>
          <img className={style.iphoneShell} id="iphoneShell" src={IphoneShell} alt="Iphone Shell" />
          <div className={style.phoneContent}>{children}</div>
        </div>
      );
    }
    return children;
  }

  return <div className={style.rightContainer}>{!forcePreview ? renderWrapper(<Creative {...creativeProperties} />) : <Creative {...creativeProperties} />}</div>;
}

export default RightPanel;
