import { Form, Select } from "@ogury/design-system";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AdUnitsService from "services/AdUnitsService/AdUnitsService";
import ReferenceService, { adUnitUrlValues } from "services/ReferenceService/ReferenceService";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { SiteData, UnitData } from "../Types";

type Props = {
  siteData: SiteData;
  adUnits: Array<UnitData>;
};

function DesktopFormTab({ siteData, adUnits }: Props): ReactElement {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const adUnitOptions = useMemo(
    () =>
      adUnits
        .map((unit) => {
          const matchedAdUnit = AdUnitsService.adUnits.find((item: any) => item.technicalId === unit.technicalId);
          if (matchedAdUnit) {
            const adUnitName = (matchedAdUnit as any).name;
            return { value: unit.externalId, label: adUnitName, technicalId: unit.technicalId };
          }
          return undefined;
        })
        .filter((item) => !!item),
    [siteData]
  );
  adUnitOptions.unshift({ value: "", label: t("DesktopForm.allLabel"), technicalId: "" });

  const initialValues = {
    adUnit: "",
  };

  const handleAdUnitChange = (value: string, item: { technicalId: string; group: string }) => {
    if (value) {
      // refresh page for proper creative reload
      navigate({
        pathname: window.location.pathname,
        search: createSearchParams({
          ...Object.fromEntries(searchParams),
          [ReferenceService.urlParameters.CID]: value,
          [ReferenceService.urlParameters.AU]: adUnitUrlValues.BANNER.key,
        }).toString(),
      });
    }
  };

  return (
    <Form form={form} initialValues={initialValues}>
      <Form.Item disabled name="adUnit" label={t("MobileForm.adUnitLabel")} required>
        <Select width="100%" disabled onChange={handleAdUnitChange} options={adUnitOptions} />
      </Form.Item>
    </Form>
  );
}

export default DesktopFormTab;
