import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { CreativeService, DebugService, ReferenceService, URLService } from "services";
import { Loader } from "components";
import scrollDownIcon from "assets/img/ScrollDownIcon.svg";
import { CreativeDataProps } from "types/Types";
import { ErrorPage } from "app/Pages";
import useAdBlockerDetection from "hooks/useAdBlockerDetection";
import { adUnitUrlValues, deviceType } from "services/ReferenceService/ReferenceService";

import style from "./Creative.module.scss";

type CreativeProps = {
  creativeData: CreativeDataProps;
  device: string;
  forcePreview?: boolean;
};

type Resources = {
  navigationbarSource?: string;
  contentSource?: string;
  contentTopSource?: string;
  contentBottomSource?: string;
};

const scrollDownIconMarginTop = "110px";

function Creative({ creativeData, device, forcePreview }: CreativeProps): ReactElement {
  const adBlockerActive = useAdBlockerDetection();
  const { creativeId, adUnit, mediaType, environment, publisherId, creativeRatio, clickUrl } = creativeData;
  const [resources, setResources] = useState<Resources>();
  const [onError, setOnError] = useState(false);
  const [isResourcesLoading, setIsResourcesLoading] = useState(true);

  const deviceValue = useMemo(() => {
    if (device === deviceType.MOBILE) {
      return deviceType.MOBILE;
    }

    if (window.innerWidth < 1600) {
      return deviceType.TABLET;
    }

    return deviceType.DESKTOP;
  }, []);

  const adUnitData = useMemo(() => {
    const data = ReferenceService.dictionary.adUnitDictionary(creativeRatio, deviceValue, forcePreview).find((obj) => obj.key === adUnit);
    if (data?.key === adUnitUrlValues.BANNER.key && creativeData.dimension) {
      data.dimension = creativeData.dimension;
    }

    return data;
  }, [adUnit]);
  const environmentValue = useMemo(
    () => ReferenceService.dictionary.environmentDictionary.find((obj) => obj.key === environment)?.value || ReferenceService.constants.ENV_MWEB,
    [environment]
  );

  async function loadResources() {
    let contentSource;
    let navigationbarSource;
    let contentTopSource;
    let contentBottomSource;

    try {
      if (adUnit === ReferenceService.adUnitUrlValues.IN_ARTICLE.key) {
        contentTopSource = await CreativeService.getContentTop(environmentValue, publisherId);
        contentBottomSource = await CreativeService.getContentBottom(environmentValue, publisherId);
      } else {
        navigationbarSource = await CreativeService.getNavigationBar(environmentValue, publisherId);
        contentSource = await CreativeService.getContent(environmentValue, publisherId);
      }

      setResources({ contentSource, contentTopSource, contentBottomSource, navigationbarSource });
    } catch (error) {
      // @ts-ignore
      DebugService.error(error);
      setOnError(true);
    } finally {
      setIsResourcesLoading(false);
    }
  }

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data === "adError") {
          DebugService.error("An error has been thrown by the mWeb tag, please check the player.js traces above for more information.");
          setOnError(true);
        }
      },
      false
    );
    loadResources();
  }, []);

  function renderAdBlockerActive() {
    if (adBlockerActive) {
      return <ErrorPage error={{ type: ReferenceService.errorType.AD_BLOCKER_ON }} />;
    }
    return null;
  }

  function renderLoading() {
    if (!onError && !adBlockerActive && isResourcesLoading) {
      return <Loader />;
    }
    return null;
  }

  function renderError() {
    if (onError && !adBlockerActive) {
      return <ErrorPage error={{ type: ReferenceService.errorType.INTERNAL }} />;
    }
    return null;
  }

  function renderScrollBackground() {
    if (!onError && !isResourcesLoading && !adBlockerActive && adUnit === ReferenceService.adUnitUrlValues.HEADER.key) {
      return `
        <div
          id="scrollDownBackground"
          role="presentation"
          onMouseEnter="event.target.remove()"
          onTouchStart="event.target.remove()"
          onwheel="event.target.remove()"
          onKeyDown="event.target.remove()"
          style="
            z-index:1;
            height : 100%;
            width : 100%;
            position:absolute;
            display: flex;
            justify-content: center;
            background: rgba(0, 0, 0, 0.6);
          ">
          <img style="width : 100px; position : absolute; margin-top : ${scrollDownIconMarginTop};" src="${scrollDownIcon}" alt="Scroll down icon" />
        </div>
      `;
    }
    return "";
  }

  function specificBidResponseForInArticle() {
    if (adUnit === ReferenceService.adUnitUrlValues.IN_ARTICLE.key) {
      return `in_article_type : "${creativeData.inArticleType}",
              creative_ratio : ${creativeData.creativeRatio}`;
    }
    return "";
  }

  function specificParamsForInterstitialAndBanner() {
    if (adUnit === ReferenceService.adUnitUrlValues.INTERSTITIAL.key || adUnit === ReferenceService.adUnitUrlValues.BANNER.key) {
      return `x_margin : 0, 
              y_margin : 0`;
    }
    return "";
  }

  function renderBackgrounds() {
    if (adUnit === adUnitUrlValues.BANNER.key) {
      return `<header id="page-header"></header>`;
    }
    if (adUnit === ReferenceService.adUnitUrlValues.IN_ARTICLE.key) {
      return ` 
        <img 
          src="${resources?.contentTopSource}"
          width="100%"  
          style="vertical-align: middle"
          alt="Article part. 1"
        />
        <div id="ad-slot"></div>
        <img 
          src="${resources?.contentBottomSource}"
          width="100%"  
          style="vertical-align: middle"
          alt="Article part. 2"
        />
      `;
    }

    // limit html display for finished publishers
    const desktopSupportPublishers = [
      "travelingmom-usa",
      "variety-us",
      "eslamoda-mx",
      "vogue-fr",
      "skysports-uk",
      "elle-de",
      "amica-it",
      "eldesmarque-es",
      "autoweek-nl",
      "marieclairejapon-jp",
      "8days-sg",
      "happymag-au",
    ];
    if (publisherId && desktopSupportPublishers.includes(publisherId) && adUnit === adUnitUrlValues.THUMBNAIL.key) {
      return `
      <iframe src="/resources/${publisherId}/article.html" style="position: relative; z-index: 0; height: 100%; width: 100%; border: 0"></iframe>
        ${renderScrollBackground()}
      `;
    }

    return `
      <img 
        src="${resources?.navigationbarSource}"
        width="100%"  
        style="vertical-align: middle"
        alt="Navigation bar"
      />
      <header id="page-header"></header>
      ${renderScrollBackground()}
      <img 
        src="${resources?.contentSource}"
        width="100%"  
        style="vertical-align: middle"
        alt="Content"
      />
    `;
  }

  // eslint-disable-next-line consistent-return
  const renderIframe = useCallback(() => {
    DebugService.info(`Rendering the internal iframe with dimensions (${adUnitData?.dimension.width}x${adUnitData?.dimension.height})`);
    if (!isResourcesLoading) {
      return (
        <iframe
          id="rendering-iframe"
          className={style.contentIframe}
          title="render"
          srcDoc={`
          <html>
            <style>
              html, body { height: 100%; width: 100%; padding: 0; margin: 0;}
               body {
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-y: scroll; 
              }
              html {
               scrollbar-width: none;
              }
              body::-webkit-scrollbar {
                display: none;
              }
              
              @property --p {
                syntax: '<percentage>';
                initial-value: 0%;
                inherits: false
              }
              
              @keyframes p { to { --p: 100% } }

          
              body.banner::before,
              .page-header::before,
              .ad-slot::before {
                content: "";
                display: block;
                border: 3px solid #0077c7;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                position: relative;
                z-index: 200;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                mask: 
                  linear-gradient(transparent 0 0) padding-box, 
                  conic-gradient(red var(--p), transparent 0%) border-box;
                animation: p 4s linear infinite
              }

            </style>
            <script>
              // We define a global variable, which indicates that we are running in the Demo Page environment, which is helpful for the OCS creatives
              window.isOgyDemoPage = true;
            </script>
            <body class=${adUnit === adUnitUrlValues.BANNER.key ? "banner" : ""}>
              ${renderBackgrounds()}
              <div>
                <script id="og-configure-player">
                  window.OG_SHOWCASE = {
                          CONFIG : {
                              bid_response: {
                                  height: "${adUnitData?.dimension.height}",  
                                  width: "${adUnitData?.dimension.width}",
                                  ad_content: "${URLService.getPreviewEndpointURL(creativeId, adUnitData?.value_adexperience_param, clickUrl, deviceValue)}",
                                  media_type: "${mediaType}",
                                  ad_unit_type: "${adUnitData?.value_media_type}",
                                  ${specificBidResponseForInArticle()}
                              },
                              ad_unit_config: {
                                  asset_key: "OGY-C3FE4ADC0126",
                                  ad_unit_id: "0251ec40-fc3b-0139-afe5-0242ac120004",
                                  callbacks: {
                                    on_error: (adUnit) => { window.parent.postMessage('adError', '*'); },
                                    on_ad_show: ()=>{ console.log("The Ad had been successfully loaded."); }
                                  },
                                  params : {
                                    header_selector : "#page-header",
                                    ad_slot_selector : "#ad-slot",
                                    ${specificParamsForInterstitialAndBanner()}
                                  }
                              }
                          }
                      }
                  </script>
                  <script id="ogy-loader" type="text/javascript" src="${URLService.getMwebTagScriptURL()}"></script>
              </div>
            </body>
          </html>
        `}
        />
      );
    }
  }, [resources]);

  function renderBody() {
    if (!onError && !isResourcesLoading && !adBlockerActive) {
      return renderIframe();
    }
    return null;
  }

  return (
    <>
      {renderAdBlockerActive()}
      {renderLoading()}
      {renderError()}
      {renderBody()}
    </>
  );
}
export default Creative;
