import { ReactElement } from "react";
import { useSearchParams } from "react-router-dom";

import { CreativeDataProps } from "types/Types";
import { ReferenceService } from "services";
import { deviceType } from "services/ReferenceService/ReferenceService";
import { LeftPanel, RightPanel } from "./components";
import styles from "./DemoPage.module.scss";

type Props = { forcePreview?: boolean; creativeData: CreativeDataProps | undefined };
function DemoPage({ forcePreview, creativeData }: Props): ReactElement {
  const [previewParams] = useSearchParams();
  const deviceParam = previewParams.get(ReferenceService.urlParameters.DEVICE) || deviceType.MOBILE;

  const handleDeviceChange = (device: string) => {
    previewParams.set(ReferenceService.urlParameters.DEVICE, device);
    window.location.href = `${window.location.pathname}?${previewParams.toString()}`;
  };

  return (
    <div className={styles.mainContainer}>
      {creativeData !== undefined && (
        <>
          {!forcePreview && <LeftPanel device={deviceParam} onDeviceChange={handleDeviceChange} creativeData={creativeData} />}
          <RightPanel forcePreview={forcePreview} device={deviceParam} creativeData={creativeData} />
        </>
      )}
    </div>
  );
}
export default DemoPage;
