import { ReactElement, useEffect, useState } from "react";
import { CreativeDataProps } from "types";
import { Card, Spin, theme, Typography, useAppNotification } from "@ogury/design-system";
import { Creative } from "app/Pages/DemoPage/components/RightPanel/components";
import { deviceType } from "services/ReferenceService/ReferenceService";
import styles from "./DesktopContent.module.scss";
import { UnitData } from "../Types";
import { CreativeService } from "../../../../services";
import debugService from "../../../../services/DebugService/DebugService";

type Props = {
  creativeData: CreativeDataProps;
  forcePreview: boolean;
  adUnits: Array<UnitData>;
};

function BannerCard({ forcePreview, data }: { forcePreview?: boolean; data?: CreativeDataProps }) {
  if (!data) {
    return (
      <Card size="small" className={styles.bannerWrapper}>
        <Spin spinning />
      </Card>
    );
  }

  return (
    <Card size="small" className={styles.bannerWrapper}>
      <div style={{ height: `${data.dimension?.height}px`, width: `${data.dimension?.width}px` }}>
        <Creative creativeData={data} device={deviceType.DESKTOP} forcePreview={forcePreview} />
      </div>
      <div className={styles.bannerMeta}>
        <Typography.P2Regular color={theme.colors.content.secondary}>Size</Typography.P2Regular>
        <Typography.P2Strong>
          {data.dimension?.width}x{data.dimension?.height}
        </Typography.P2Strong>
      </div>
    </Card>
  );
}

function DesktopContent({ creativeData, forcePreview, adUnits }: Props): ReactElement {
  const notificationService = useAppNotification();
  const [creativesDataList, setCreativesDataList] = useState<Array<CreativeDataProps>>([]);

  useEffect(() => {
    async function loadCreativesData(additionalCreativesData: any) {
      setCreativesDataList(await Promise.all(additionalCreativesData));
    }

    try {
      const additionalCreativesData = adUnits
        // .filter((adUnit) => adUnit.externalId !== creativeData.creativeId)
        .map(async (adUnit) => {
          const data = await CreativeService.getCreativeData(adUnit.externalId);
          const creativeSize = data.params.creative_size ? data.params.creative_size.split("x") : [];
          return {
            creativeId: data?.id,
            name: data?.name,
            brand: data?.brand?.name,
            account: data?.account?.name,
            adFormat: data?.adFormat?.name,
            mediaType: data?.adFormat?.mediaType,
            inArticleType: data?.adFormat?.inArticleType,
            adUnit: adUnit.urlKey,
            dimension: creativeSize.length === 2 ? { width: creativeSize[0], height: creativeSize[1] } : undefined,
            // @ts-ignore
            creativeRatio: data?.params?.creative_ratio || 0,
            clickUrl: creativeData?.clickUrl,
          };
        });

      loadCreativesData(additionalCreativesData);
    } catch (err: any) {
      notificationService.error({ message: err.message });
      debugService.error(err.message);
    }
  }, []);

  return (
    <Card className={styles.mainWrapper}>
      {creativesDataList.length === 0 && adUnits.length > 1 && <BannerCard />}
      {creativesDataList
        .sort((a: CreativeDataProps, b: CreativeDataProps) => (a.dimension && b.dimension ? a.dimension.width / a.dimension.height - b.dimension.width / b.dimension.height : 0))
        .map((creative) => (
          <BannerCard data={creative} forcePreview={forcePreview} />
        ))}
    </Card>
  );
}

export default DesktopContent;
