import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { ReferenceService, URLService } from "services";
import OguryLogo from "assets/img/Logo.svg";
import { Divider, Link, Row, Typography } from "components";
import { Select } from "@ogury/design-system";
import SmartphoneLineIcon from "remixicon-react/SmartphoneLineIcon";
import MacLineIcon from "remixicon-react/MacLineIcon";

import { deviceType } from "services/ReferenceService/ReferenceService";
import { CreativeDataProps } from "types";
import style from "./LeftPanel.module.scss";

type LeftPanelProps = {
  creativeData: CreativeDataProps;
  device: string;
  // eslint-disable-next-line no-unused-vars
  onDeviceChange: (device: string) => void;
};

function LeftPanel({ device, creativeData, onDeviceChange }: LeftPanelProps): ReactElement {
  const { t } = useTranslation();
  const allParams = window.location.href.split("?");

  const optionDeviceRenderer = ({ value, label }: { value: string; label: string }): ReactElement => (
    <div className={style.viewMenuItem}>
      {value === deviceType.MOBILE ? (
        <>
          <SmartphoneLineIcon size={16} /> {label}
        </>
      ) : (
        <>
          <MacLineIcon size={16} /> {label}
        </>
      )}
    </div>
  );
  const adUnit = useMemo(() => ReferenceService.dictionary.adUnitDictionary().find((cty) => cty.key === creativeData.adUnit), []);
  const canSwitchMobileDesktop = useMemo(() => creativeData.adUnit === ReferenceService.adUnitUrlValues.THUMBNAIL.key, [creativeData]);
  const isBanner = useMemo(() => creativeData.adUnit === ReferenceService.adUnitUrlValues.BANNER.key, [creativeData]);

  function renderAdUnitLabel() {
    if (isBanner) {
      return `${adUnit?.label} ${creativeData?.dimension?.width}x${creativeData?.dimension?.height}`;
    }
    return adUnit?.label;
  }

  return (
    <div className={style.container}>
      <div>
        <Row>
          <img id="logo" src={OguryLogo} alt="Ogury logo" />
        </Row>
        {creativeData.advertiserName && (
          <>
            <Row top={40}>
              <Typography variant="body1">{t("DemoShowcase.advertiser", "Advertiser")}</Typography>
            </Row>
            <Row className={style.breakNewLine}>
              <Typography variant="body1Bold">{creativeData.advertiserName}</Typography>
            </Row>
          </>
        )}
        <Row top={16}>
          <Divider />
        </Row>
        <Row top={16}>
          <Typography variant="body1">{t("DemoShowcase.adFormat", "Ad Format")}</Typography>
        </Row>
        <Row>
          <Typography variant="body1Bold">{renderAdUnitLabel()}</Typography>
        </Row>

        <Row top={16}>
          <Divider />
        </Row>
        {canSwitchMobileDesktop && (
          <Row top={16}>
            <Select
              width="100%"
              onChange={onDeviceChange}
              value={device}
              optionRenderer={optionDeviceRenderer}
              options={[
                {
                  label: "Mobile",
                  value: deviceType.MOBILE,
                },
                {
                  label: "Desktop",
                  value: deviceType.DESKTOP,
                },
              ]}
            />
          </Row>
        )}
        {!isBanner && (
          <>
            <Row top={40} padding={3} className={style.QRcodeBorder}>
              <QRCode value={`${URLService.getPublicURL()}?${allParams[1]}`} bgColor="#FFFFFF" fgColor="#000000" size={140} />
            </Row>
            <Row>
              <Typography variant="body2">{t("DemoShowcase.seeMoreOnPhone", "See this demo on your phone")}</Typography>
            </Row>
          </>
        )}
      </div>
      <Row>
        <Link href="https://www.ogury.com" text={t("DemoShowcase.learMoreAboutOgury", "Learn more about Ogury Ads")} />
      </Row>
    </div>
  );
}
export default LeftPanel;
